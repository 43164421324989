.sports-picker ul {
    display: block;
    margin: auto;
    text-align: center;
    height: calc(100vh - 360px);
    overflow-y: auto;
    border-top: 1px solid var(--light-border-color);
    border-bottom: 1px solid var(--light-border-color);
    padding: 1rem 0;
    
}

.sports-picker ul.edit-mode {
    height: calc(100vh - 260px);
}

.sports-picker ul li {
    list-style: none;        
    margin: .5rem;
    display: inline-block;
}

.sports-picker ul li button {
    width: 300px;
    background-color: var(--light-background-color);
    text-align: left;
    display: flex;
    align-items: center;
    border: 1px solid var(--light-border-color);

}

.sports-picker ul li button.select {
    border-color: var(--counter-color);    
    background-color: var(--counter-color);

}

