.reported .reported-table {
    padding: .5rem;
}

.reported table.reported-table tbody tr td, 
.reported table.reported-table thead tr th {
    padding: .75rem .5rem;
}

.reported table.reported-table tbody tr td button {
    font-size: .85rem !important;
    text-align: left;
}

.reported .feed {
    position: relative;
    padding: 0;
}

.reported .feed ul li {
    padding: 0;
}

.reported .feed ul li .feed-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center ;
}

.reported .feed ul li .feed-actions,
.reported .feed ul li .show-more {
    display: none;
}

.reported .feed .remove-actions {
    position: absolute;
    width: 100vw;
    max-width: var(--app-width);
    text-align: center;
    z-index: 99999;
    padding: 0rem;
    box-sizing: border-box;
    background-color: rgba(0,0,0,.8);
    border-top: 1px solid var(--brand-border-color);
    border-bottom: 1px solid var(--brand-border-color);
    top: 0;
}
.reported .feed .remove-actions button {
    margin: .5rem;
}

.reported .drop-down select {
    font-size: .85rem;
}

.reported select.report-picker {
    display: inline-block;
    width: 300px;
    text-align: center;
    margin-bottom: 1rem;
}

.reported .total-count {
   font-size: .8rem;
   padding-bottom: .25rem;
   color: var(--brand-red);
}