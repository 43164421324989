.user-settings {
    position: fixed;
    top: 0px;
    background-color: black;
    width: 100%;
    height: calc(100vh);
    max-width: var(--app-width);
    z-index: 9999999;
    transform: translateX(100vw);
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    overflow: hidden;
}

.user-settings .user-settings-inner {
    height: calc(100vh - var(--header-bar-height) - var(--ios-top-pad));
    overflow-y: auto;
}

.user-settings .settings-form {
    padding: 1rem 1rem 5rem;    
}
.user-settings .settings-form.pop-form {
    padding: 0;
}

.user-settings form {
    display: block;
    border: none;
}

.user-settings form label {
    display: block;
    border: none;
}

.user-settings form div.form-label {
    border: none;
    display: block;
    margin-bottom: 0;
    color: silver;
}

.user-settings form div {
    display: block;
    border: 1px solid var(--light-border-color);
    margin-bottom: .5rem;
    width: 100%;
}

.user-settings form label div input {
    display: block;
    border: none;
    width: 100%;
    background-color: black;
}

.user-settings .settings-form .PhoneInput {
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.user-settings div.email-input-wrapper {
    padding: 0
}

.user-settings .settings-form .PhoneInputCountry {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;    
    margin: 0;    
}

.user-settings .settings-form .PhoneInputCountrySelect {
    padding: 0;
    border: none;
}

.user-settings .settings-form .PhoneInputCountryIcon--border {
    border: none;
    margin: 0;
}

.user-settings .settings-form .PhoneInputCountrySelectArrow {
    display: none;
}

.user-settings .settings-form .PhoneInputInput {
    flex: 1 1;
}

.user-settings .settings-form .PhoneInputInput:disabled {
    background-color: black;
    color: var(--brand-text-color);
    border: none;
    max-width: 250px;
}

.user-settings .settings-form div.email-field,
.user-settings .settings-form div.phone-field {
    display: flex;
    align-items: center;    
    min-height: 30px;
    box-sizing: border-box;
    background-color: black;
    color: var(--brand-text-color);
}



.user-settings .settings-form div.sports-field {
    padding: .75rem .2rem;
    display: flex;
    align-items: center;
}

.user-settings .settings-form div.sports-field .tag {
    display: inline-block;
    margin: .25rem;
}

.user-settings .sheen {
    top: var(--header-bar-height);
}

/* 
.user-settings .settings-form select {
    border: none;
    padding: 0;
} */

.user-settings .settings-avatar,
.user-settings .avatar-placeholder {
    height: 100px;
    width: 100px;
    border-radius: 100%;
}

.user-settings .avatar-placeholder {
    font-size: 80px;
    line-height: 100px;
    color: var(--body-background-color);
}

.user-settings.visible {
    transform: translateX(0);
}

.user-settings .user-menu-header {
    display: flex;
    text-align: center;
    align-items: center;
    margin-top: var(--ios-top-pad);
    padding: 0 1rem;
    height: var(--header-bar-height);        
    box-sizing: border-box;
}

.user-settings .user-menu-header button {
    font-size: .8rem;
}

.user-settings .user-menu-header .user-menu-header-label {
    flex: 1 1;
}

.user-settings .hero-image {
    height: 200px;
    overflow: hidden;
    position: relative;
}

.user-settings .hero-image img {
    width: 100%;
}

.user-settings button.validate {
    background-color: var(--brand-red);
    color: white;
}