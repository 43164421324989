html,
body,
#root {
  height: 100%;
  background-color: black;
}

/* * :focus-visible {
  outline: none;
}
 */
body {
  background-color: black;
  color: var(--brand-text-color);
  margin: auto;
  text-align: center;
  width: var(--app-width);
  max-width: var(--app-width);
  overflow: hidden;
  font-family: var(--app-font-family);
  letter-spacing: var(--letter-spacing);
  font-size: 100%;
  line-height: var(--app-line-height);
  box-sizing: border-box;
  max-width: 100vw;
}

a {
  color: var(--brand-text-color);
  text-decoration: none;
}

button,
.button {
  padding: .5rem 1.2rem;
  border-radius: 5px;
  background-color: var(--brand-button-color);
  color: var(--brand-button-text-color);
  border: none;
  letter-spacing: var(--letter-spacing);
  font-size: var(--app-font-size);
}

button:disabled {
  opacity: .5;
}

.button-cancel {
  background-color: var(--brand-grey)
}

.top-mask {
  position: absolute;
  top: 0;
  height: 15rem;
  width: 100vw;
  max-width: var(--app-width);
  background: linear-gradient(180deg, rgba(0,0,0,0.28895308123249297) 0%, rgba(255,255,255,0) 100%);
  z-index: 8;
}

.bg-gainsboro {
  background-color: gainsboro;
  color: black;
}

.button-save {
  background-color: var(--brand-blue);
}

.link-button {
  text-decoration: none !important;
  padding: .5rem 1rem;
  border-radius: 3px;
  display: inline-block;
}

table {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

th,
td {
  font-weight: normal;
  box-sizing: border-box;

  padding: 3%;
  vertical-align: top;
}

th {
  background-color: var(--th-background-color);
}

td {
  background-color: var(--td-background-color);
}

.label-required {
  position: relative;
}

.label-required::after {
  content: '*';
  color: var(--brand-gold);
  font-size: 1.5rem;
  position: relative;
  top: 0px;
}

.required-instruct {
  display: flex;
  align-items: center;
  text-align: left;
  padding-bottom: .5rem;
}

.required-instruct :first-child {
  color: var(--brand-gold);
  margin-right: .25rem;
}

.app {
  height: 100vh;
  /* calc(100vh - var(--header-bar-height)); */
  overflow-y: auto;
  background-color: var(--app-background-color);
  /* padding-top: var(--header-bar-height); */
  /* scroll-snap-type: y mandatory;
  scroll-behavior:auto; */
}

.app .app-inner {
  height: 100%;
  min-height: 100%;
  background-color: black;
}

.medium-logo {
  width: 60px;
}

.counter {
  position: absolute;
  font-size: .8rem;
  /* background-color: var(--counter-color); */
  color: white;
  /* min-width: 20px;
  height: 20px;
  line-height: 20px; */
  text-align: left;
  /* order-radius: 100%; */
}

.anchor-button {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  color: unset;
}

.feed ul li.no-data {
  display: block;
  text-align: center;
  height: unset;
  padding: 0;
  margin-top: -1.65rem;
}

.feed ul li.no-data img {
  width: 100px;
}

.camera-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  width: 36px;
  height: 36px;
  line-height: 32px;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 100%;
  border: 2px solid white;
}

.button-red {
  background-color: var(--brand-red);
  border-color: var(--brand-red);
  color: white;
  ;
}

.pop-menu {
  position: absolute;
  background-color: var(--drop-menu-background-color);
  /* box-shadow: 2px 3px 3px dimgray;   */
  border: 2px solid var(--drop-menu-border-color);
  display: block;
  right: 10px;
  top: 40px;
  border-radius: 3px;
}

.pop-menu li,
.feed .pop-menu li {
  list-style: none;
  text-align: left;
  padding: .5rem 1rem;
  font-size: .8rem;
  margin: 0;
}

.pop-menu li button,
.feed .pop-menu li button {
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
}

.pop-menu li button .menu-pointer,
.feed .pop-menu li button .menu-pointer {
  position: absolute;
  color: var(--drop-menu-border-color);
  top: -23px;
  right: -13px;
  font-size: 1.3rem;
}

.pop-menu li .menu-icon,
.feed .pop-menu li .menu-icon {
  margin-right: .6rem;
  font-size: 1rem;
}

.form-control {
  background-color: var(--form-input-color);
  color: #ffffff;
  margin-bottom: 12px;
  border: 0px solid #000;
  border-radius: 4px;
  line-height: 2.5;
  padding: 8px 20px;

}


.public-logo img {
  width: 150px;
}

.required {
  border: 1px solid var(--brand-pink) !important;
}


.brand-red {
  color: var(--brand-red);
}

.brand-pink {
  color: var(--brand-pink);
}

.bg-brand-pink {
  background-color: var(--brand-pink);
  color: white;
}

.bg-brand-red {
  background-color: var(--brand-red);
  color: white;
}

.bg-transparent {
  background-color: transparent;
}

.brand-green {
  color: var(--brand-green);
}

.brand-dark-green {
  color: var(--counter-color);
}

.brand-gold {
  color: var(--brand-gold);
}

.brand-orange {
  color: var(--brand-orange);
}

.brand-grey {
  color: var(--brand-grey);
}

.bg-gainsboro {
  background-color: gainsboro;
  color: black;
}

.bg-brand-dark-green {
  background-color: var(--counter-color);
  color: white;
}

.bg-brand-gold {
  background-color: var(--brand-gold);
  color: black;
}

.bg-brand-background {
  background-color: var(--brand-background-color);
  color: white;
}

.bg-black {
  background-color: black;

}

.brand-button-color {
  color: var(--brand-button-color);
}

.brand-link-color {
  color: var(--brand-link-color);
}

.menu-sheen,
.sheen {
  z-index: 9999;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  max-width: var(--app-width);
  background-color: black;
  opacity: .6;
  transition: opacity .3s ease-in-out;
}

select {
  padding: .5rem;
  background-color: black;
  color: white;
  border-radius: 3px;
  border: 1px solid var(--light-border-color);
  box-sizing: border-box;
  width: 100%;
}

select:focus-visible {
  outline: none;
}

textarea {
  font-family: var(--app-font-family);
}

form {
  text-align: left;
  width: 100%;
  display: table;
  border-top: 1px solid rgba(255, 255, 255, .25);
}

form label .form-label {
  font-size: .9rem;
}

form label {
  display: table-row;
}

form div {
  display: table-cell;
  padding: .2rem;
  border-bottom: 1px solid rgba(255, 255, 255, .25);
  vertical-align: middle;
}

form div.form-label {
  padding-top: .75rem;
}

form label div input,
form label div textarea,
form label div select {
  width: 100%;
  border: 1px solid transparent;
  color: var(--brand-text-color);
  padding: .75rem .2rem;
  background-color: var(--form-input-color);
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 1rem;
}

form label div select {
  background-color: black;

}

input,
select,
textarea {
  font-size: var(--app-font-size);
  letter-spacing: var(--letter-spacing);
}

input:focus-visible,
textarea:focus-visible {
  outline-offset: 0;
  outline: none;
}

.footer-mini {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  z-index: 88;
  padding-bottom: 1rem;
  padding-top: .25rem;
  text-align: center;
}

.footer-mini.repo {
  top: 0;
  left: unset;
  transform: unset;
  bottom: unset;
  width: 100vw;
  z-index: 9999999;
}

.footer-mini.repo button.mini-add {
  display: none;
}

.footer-mini button.mini-add {
  font-size: 2.5rem;
  /* color: rgba(255,255,255,.9);   */
  color: gainsboro;
  opacity: .9;
  border-radius: 100%;
  /*  border: 1px solid silver; */
  display: inline-block;
  line-height: 0;
  letter-spacing: 0;
  background-color: black;
  transition: all .3s ease-in;
}

.footer-mini button.fadeout {
  opacity: 0;
  transition: all .3s ease-in-out;
}

.tabs,
.big-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--app-width);
  text-align: center;
  /* background-color: var(--main-header-color); */
  /* padding: 1rem 0 .5rem 0; */
  /* padding-top: 1rem; */
  box-sizing: border-box;
  border: none;
  color: silver;
  height: var(--tab-bar-height);
  background-color: transparent;
  border-block-end-color: transparent;
  border-block-start-color: transparent;
  border-inline-end: transparent;
  border-inline-start-color: transparent;
  border-color: transparent;
  flood-color: transparent;
  outline-color: transparent;
  caret-color: transparent;
  fill: transparent;
  stroke-opacity: 0;
  --webkit-tap-highlight-color: transparent;
  border-collapse:collapse;
}

.big-tabs {
  text-align: left;
  justify-content: center;
  padding: 0 1rem;
  height: unset;
  /* border-bottom: 1px solid var(--light-background-color); */
}

.tab {
  display: inline-flex;
}

.big-tab {
  display: block;
  background-color: var(--light-background-color);
  border-radius: 10px 10px 0 0;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.tab button {
  color: white;
  padding: 0 .5rem .25rem;
  margin: 0 .25rem;
  border-bottom: 2px solid transparent;
  font-size: 1rem;
  text-shadow: var(--text-shadow);
  background-clip: border-box;
  border-image-width: 0;
}

/* .tab button:focus-visible {
  outline: none;
}
 */
.big-tab button {
  text-align: left;
  padding: 1rem .75rem .5rem;
  border-bottom: 3px solid transparent;
  line-height: 1.7rem;
}

.active-tab button,
.big-active-tab button {
  border-color: white;
}

.big-active-tab button {
  background-color: rgba(255, 255, 255, .1);
}

ul {
  margin: 0;
  padding: 0;
}

ul.no-bullets li {
  list-style: none;
  text-align: left;
}

ul.flex-li li {
  display: flex;
  align-items: center;
}

.avatar-large {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 100%;
  background-color: var(--avatar-background-color);
  border: 1px solid var(--light-background-color);
}

.hero .hero-image {
  height: 200px;
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero .hero-header .hero-avatar {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

}

.tags {
  display: block;

}

.tag {
  display: inline-block;
  padding: 0 .3rem;
  border: 1px solid var(--drop-menu-border-color);
  background-color: black;

  border-radius: 5px;
  font-size: .8rem;
  margin: .25rem;
  font-weight: normal;
}

.tags .tag:first-child {
  margin-left: 0;
}

.tags .tag:last-child {
  margin-right: 0;
}

button.tag-close {
  width: 23px;
  /* background-color: rgba(255,255,255,.6); */
  /* border-radius: 102%; */
  height: 22px;
  /* line-height: 21px; */
  text-align: center;
  color: silver;
  font-size: .6rem;
  margin-right: -4px;
}


@media (min-width: 768px) {

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .3);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {}
}

/** Edit Icon css on edit post**/

.add-content {
  position: relative;
}

/* label i.fa-square-pen {
  position: absolute;
  right: 15px;
  z-index: 9999;
  top: 85px;
  font-size: 20px;
  text-shadow: var(--text-shadow);
} */