.slideover {
  position: fixed;
  top: 0px;  
  background-color: black;
  width: 100%;
  height: 100%;
  max-width: var(--app-width);
  z-index: 9999999;
  transform: translateX(100vw);
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
  }

  .slideover.visible {
    transform: translateX(0);
}
  
  .slideover .slideover-header {
    display: flex;
    margin-top: var(--ios-top-pad);
    padding: 0 .5rem;
    align-items: center;
    width: 100%;
    max-width: var(--app-width);
    text-align: center;
    position: relative;
    box-sizing: border-box;
    height: var(--header-bar-height);
    background-color: var(--popover-header-color);
  }

  .slideover .slideover-header button {
    
      margin-right: .5rem;
      width: 32px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      left: .25rem;
      font-size: 1rem;
 
  }

  .slideover .slideover-content {
    
    background-color: black;
    height: calc(100vh - var(--header-bar-height) - var(--ios-top-pad));
    overflow-y: auto;
  }

  .slideover .slideover-content.scrollSnap {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
  }
