form .edit-email {
    margin: auto;
    
}
form .edit-email div {
    border: none;
    width: unset;
    margin: 0;
}
form .edit-email div.dialog-pop {
    width: 100%;
   left: 0;
}

form .edit-email div.dialog-pop-wrapper {
    margin: 0;
    border: none;
    padding: 0;
}

form .edit-email div.dialog-pop .dialog-header {
    margin-bottom: 0;
}

.user-settings .settings-form .edit-email div.email-field {
    display: flex;
    align-items: center;
    min-height: 30px;
    box-sizing: border-box;
    background-color: black;
    border: 1px solid var(--light-border-color);
    padding: .2rem;
}

form .edit-email .dialog-sheen {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--app-width);
    height: 100vh;
    background-color: rgba(0,0,0,.6);
    opacity: 0;
    transition: all .3s ease-in-out;
    padding: 0;
}
form .edit-email .dialog-sheen.show {
    opacity: 1;
}