.discover-feed {
  position: relative;
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.discover-feed .tags {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  text-align: center;
}

.discover-feed .tags-header {
  border-bottom: 1px solid var(--light-border-color);
  border-top: 1px solid var(--light-border-color);
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  text-align: left;

}

.discover-feed .tags-header button {
  font-size: .85rem;
}

.discover-feed .tags-inner {
  padding: .5rem 1rem 2rem;
  height: calc(100vh - 118px);
  overflow-y: scroll;
}

.discover-feed .tags-inner .tag {

  padding: 0 .4rem;
  border: 1px solid var(--drop-menu-border-color);
  background-color: black;
  color: gainsboro;
  border-radius: 5px;
  font-size: .85rem;
  margin: .5rem;
  font-weight: normal;
}

.discover-feed .tag.selected {
  background-color: var(--counter-color);

}

.discover-feed .hero .hero-header .profile-badge {
  display: block;
  position: absolute;
  width: 40px;
  right: -4px;
  top: 10px;  
}

.discover-feed .tags .tag {
  background-color: rgba(0,0,0,.5);
  border-color: rgba(255, 255, 255, .2);
}

.discover-feed .tags .tag:first-child {
  margin-left: .25rem;
}

.discover-feed .tags .tag:last-child {
  margin-right: .25rem;
}


.discover-feed .tags-wrapper {
  position: fixed;
  width: 100vw;
  max-width: var(--app-width);
  top: calc(5.3rem + var(--ios-top-pad));
  text-align: center;
  z-index: 9;
  height: fit-content;
}

.discover-feed .tags-wrapper .tags-filter {
  position: relative;
  margin: auto;
  color: gainsboro;
  font-size: .85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discover-feed .tags-wrapper .filter-inner {
  padding: 0.3rem 1.2rem .3rem .7rem;
  border: 1px solid rgba(255, 255, 255, .4);
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .35);
  color: white;
  text-shadow: 1px 1px 1px black;
}


.discover-feed .tags-wrapper .tags-filter .filter-counter {
  /* display: inline-block; */
  font-size: .6rem;
  line-height: 17px;
  min-width: 16px;
  height: 16px;
  /* margin-left: 0.5rem; */
  /* border-radius: 100%; */
  /* background-color: gainsboro; */
  /* color: black; */
  text-align: center;
  position: absolute;
  right: 6px;
  top: -1px;
}

.discover-feed .tags-wrapper .tags-filter .filter-caret {
  border: 1px solid var(--light-border-color);
  border-left: none;
  padding: 0 .35rem;
  display: block;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 0 3px 3px 0;
}

.discover-feed .tags-wrapper .tags {
  width: 100vw;
  max-width: var(--app-width);
  opacity: 0;
  transition: opacity .3s ease-in-out;
  background-color: var(--brand-background-color);
  padding-bottom: 2.5rem;
  padding-top: .5rem;
  border-bottom: 1px solid var(--brand-border-color);
  border-top: 1px solid var(--brand-border-color);
  box-shadow: 0px 2px 10px black;
}

.discover-feed .tags-wrapper .tags.show {
  opacity: 1;
}

.discover-feed .tags-wrapper .tag-action {
  background-color: rgba(255, 255, 255, .75);
  color: black;
}

.discover-feed ul {
  padding: 0;
}

.discover-feed ul li {
  list-style: none;
  height: 100vh;
  /* calc(100vh - var(--header-bar-height) - var(--tab-bar-height)); */
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.discover-feed ul li.no-data {
  display: block;
  text-align: center;
  height: unset;
  padding: 0;
  margin-top: calc(5rem + var(--ios-top-pad));
}

.discover-feed {
  background-color: var(--app-background-color);
}

.discover-feed .hero {
  width: 100vw;
  max-width: var(--app-width);
}

.discover-feed .hero {
  padding-bottom: 0;
}

.discover-feed .hero .hero-image { 
  height: 100vh; /* calc(100vh - 20px);  */
  filter: blur(10px);
  -webkit-filter: blur(10px);
  background-position: center center;
  /* animation-iteration-count: infinite;
  animation: move-hero 10s linear infinite; */
 /*  scale: 1; */
  /* border-top: 1px solid silver;
  border-bottom: 1px solid silver; */
}

/* @keyframes move-hero {
 
  100% {transform: scale(1.1);}
} */
.discover-feed .hero .hero-header {
  text-align: center;
  margin: auto;
  padding: 0;  
  position: relative;
}

.discover-feed .profile-action {
  flex: 1 1;
  text-align: center;
  margin-top: 0;
  position: absolute;
  bottom: 6rem;
}

.discover-feed .profile-action button {
  width: 140px;
  background-color: rgba(0, 0, 0, .5);
  border: 1px solid rgba(255, 255, 255, .7);
  padding: 1rem;
  font-weight: bold;
 
  /* text-transform: uppercase; */
}



.discover-feed .profile-description {
  padding: 1rem;
  text-align: center;
}

.discover-feed h1 {
  font-size: 1.3rem;
  font-weight: normal;
  margin-bottom: .5rem;
}

.discover-feed .hero .hero-header .avatar-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.discover-feed .hero .hero-header .avatar-wrapper .avatar-name {
  border-radius: 10px;
  font-size: 1.2rem;
  background-color: var(--brand-red);
  padding: .5rem 1rem;
  font-weight: bold;
  display: inline-block;
}
.discover-feed .hero .hero-header .avatar-wrapper .subscribe-tag {
  font-size: .7rem;
  font-weight: bold;
}
.discover-feed .hero .hero-header .avatar-wrapper .subscribe-price {
  font-size: .8rem;
  font-weight: bold;
}

.discover-feed .hero .hero-header .avatar-wrapper .org-info {
  font-size: .8rem;
  font-weight: normal;
  max-width: 300px;
  min-width: 160px;
}


.discover-feed .hero .hero-header .hero-avatar {
  display: inline-block;  
  border: 4px solid white;
  width: 170px;
  height: 170px;  
}

.discover-feed .avatar-placeholder {
  border-radius: 100%;
  font-size: 50px;
  line-height: 100px;
  color: var(--body-background-color);
}

.discover-feed .close-filter {
  position: absolute;
  bottom: 10px;
  padding: .25rem .5rem;
  background-color: rgba(255, 255, 255, .75);
  border: 1px solid var(--drop-menu-border-color);
  font-size: .85rem;
  color: black;
}