.users-table {
    padding: .5rem;
}
.users-table tr :nth-child(2),
.users-table tr :nth-child(3) {
    text-align: center;
}

.users-table-wrapper table.user-details {
    font-size: .9rem;
}

.users-table-wrapper .req-dot {
    font-size: .5rem;
    color: var(--brand-gold);
    vertical-align: top;
    margin-left: .25rem;    
}

.users-table-wrapper .users-table-scroller {
    height: calc(100vh - 192px - var(--ios-top-pad));
    overflow-y: auto;
}

.users-table .deleted-tag {
    background-color: var(--brand-red);
}

.users-table-wrapper .download {
    padding: .39rem .5rem;
    border: 1px solid var(--light-border-color);
    border-right: none;
    border-radius: 3px 0 0 3px;
    
}