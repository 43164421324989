
.header-avatar.large-avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.header-avatar.large-avatar.place-holder {
    border: 2px solid silver;
}

.main-menu {
    position: fixed;
    top: 0px;
    padding-top: var(--ios-top-pad);
    padding-bottom: 1rem;
    background-color: black;
    width: 100vw;
    /* height: 100vh; */
    /* overflow-y: hidden; */
    overflow-x: hidden;
    max-width: var(--app-width);
    z-index: 9999999;
    transform: translateX(100vw);
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    box-sizing: border-box;
}


.main-menu .main-menu-body {
    overflow-y: auto;
    height: calc(100vh - var(--header-bar-height) - var(--ios-top-pad));
}
.main-menu.visible {
    transform: translateX(0);
}

.main-menu .main-menu-header {
    display: flex;
    padding: 0 .5rem;
    align-items: center;
    width: 100%;
    max-width: var(--app-width);
    text-align: center;
    position: relative;
    box-sizing: border-box;
    height: var(--header-bar-height);
    background-color: var(--popover-header-color);
}

.main-menu .main-menu-header .back-button {
    margin-right: .5rem;
    width: 32px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    left: .25rem;
    font-size: 1rem;
}


.main-menu ul.logout {
    margin-top: 2rem;
}


.main-menu ul.main-menu-section li :first-child.small-font {
    font-size: .75rem;
    margin-top: .25rem;
}

.main-menu .section-header {
    font-variant: small-caps;
    font-size: .8rem;
    color: rgba(255, 255, 255, .7);
    padding: .5rem .5rem 0;
    text-align: left;
}

.settings-block ul.block li .menu-icon.logo-icon {
    margin-right: .5rem;
}

.main-menu .menu-icon.logo-icon img {
    width: 20px;
}

.main-menu .app-mode {
    color: var(--brand-gold);
    font-size: .8rem;
}