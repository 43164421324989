.loading {
    position: fixed;
    top: var(--ios-top-pad);   
    width: 0;
    max-width: var(--app-width);
    height: .25rem;    
    background-color: var(--brand-green);
    transition: width 1s ease-in-out;
    opacity: .5;
    z-index: 99999999;
    border-radius: 5px;
}

.loading.grow {
    width: 100vw;
    transition: width .5s ease-in-out;
}

.loading.dark-loading {
    background-color: var(--brand-red);
}