.tips .tips-wrapper {
    padding: 1rem;
    
    background-color: white;
    border-radius: 10px;
    margin: 1rem;
    margin-top: 2.6rem;
    
}
.tips .tips-processing {
    height: calc(99vh - var(--ios-top-pad) - var(--header-bar-height) - 4rem);
    padding: 1rem;
}

.tips .hero-avatar {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    /* margin-bottom: -3.3rem; */
    margin-top: -3.5rem;
    z-index: 9;
    background-color: silver;
    position: relative;
}

.tips .coins-wrapper .coins {
   
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 0rem;
    /* background-color: var(--brand-background-color); */    
    white-space: nowrap;
    padding-right: 10rem;
    padding-left: 10rem;
    margin-bottom: .5rem;
    /*  border-top: 1px solid rgba(255,255,255,.2); */
    /*  border-bottom: 1px solid rgba(255,255,255,.2); */
}

.tips .coins .coin {
    width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    color: black;
    text-align: center;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
    list-style: none;
    margin: .5rem .25rem;
    font-size: .85rem;
    font-weight: bold;
    text-shadow: 1px 2px 5px gold;
    border-radius: 100%;
    border: 4px solid transparent;
    scroll-behavior: smooth;
}

.tips .reason-wrapper .reason-types {

    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    padding-right: 10rem;
    padding-left: 10rem;
    white-space: nowrap;     
    /*  border-top: 1px solid rgba(255,255,255,.2); */
    /*  border-bottom: 1px solid rgba(255,255,255,.2); */
}

.tips .reason-wrapper .reason-types .reason-type {
    height: unset;
    overflow: hidden;
    background-color: rgba(0,0,0,.07);
    
    text-align: center;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
    list-style: none;
    padding: .5rem .4rem;
    margin: .5rem;
    font-size: 1rem;
    border-radius: 3px;
    border: 2px solid transparent;
    
    
}

.tips .reason-wrapper .reason-types .reason-type.selected {
    background-color: white;
    border-color: var(--brand-dark-green);
    transition: all .3s ease-in-out;
}

.tips .reason-wrapper .reason-types .reason-type .fa {
    font-size: 1.5rem;
    color: var(--brand-blue);
}

.tips .coins .coin.selected {
    border-color: var(--brand-green);
    box-shadow: inset 1px 2px 15px var(--brand-dark-green);
    transition: all .3s ease-in-out;
}
.tips .reason-wrapper .reason-types .reason-type.selected  .fa {
    color: var(--brand-dark-green);
}
.tips .reasons {
    padding: .5rem 1rem 1rem;
}

.tips .confirm-box {
    display: inline-flex;
    margin: 0 1rem .5rem;
}

.tips a {
    font-size: .9rem;
}

.tips .policy {
    padding: .5rem 2rem;
    line-height: 25px;
}

.tips .wait-logo img {
    width: 60px;
    margin-bottom: 1rem;
}

/* .tips .scroll-hint {
    background-color: var(--brand-background-color);
    padding: .1rem 0;
} */


.tips .tip-target:focus {   
    border-radius: 5px;
   
    animation: highlight 300ms;
}

@keyframes highlight {
    0% {
       
        rotate: 5deg;
    }

    50% {
        rotate: -5deg;
    }

    100% {
       
        
    }
}