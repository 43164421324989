:root {
    --app-width: 500px;

    --ios-top-pad: 45px;
    --android-top-pad: 45px;

    --app-font-family: "Roboto", "sans-serif", "Helvetica", "Arial";
    --app-font-size: 1rem;
    --app-line-height: 23px;

    --letter-spacing: 0.06em;

    --app-background-color: black;
    --body-background-color: #8f8d9d;
    --brand-background-color:#001d4b;

    --hint-background-color: #006534;
    --hint-border-color: #00753d;

    --drop-menu-background-color: var(--brand-background-color);

    --drop-menu-border-color: rgb(87, 85, 108);

    --light-background-color: rgba(205, 224, 255, 0.2);

    --td-background-color: rgba(205, 224, 255, 0.15);

    --th-background-color: #292D33; /* rgba(205, 224, 255, 0.2); */

    --brand-text-color: #ffffff;

    --main-header-color: rgba(0, 0, 0, .5);
    --main-footer-color: rgba(0, 0, 0, .9);
    --popover-header-color: black;
    /* --popover-header-color: rgb(43, 39, 80); */
    /* --nav-header-color: rgb(43, 39, 80); */
    --nav-header-color: black;
    --header-bar-height: 45px;
    --tab-bar-height: 35px;

    --main-menu-color: #070324;

    --popover-body-color: black;

    --brand-orange: #ff5e00;
    --brand-red: #FF004E;
    --brand-pink: #fc6894;
    --brand-green: #56e39f;
    --brand-dark-green: #63ab88;
    --brand-gold: #b7df02;
    --brand-purple: #8377ff;
    --brand-blue: rgb(11,87,208);
    --brand-grey: #627476;

    --brand-button-color: #234375;
    --brand-button-text-color: #ffffff;

    --brand-border-color: rgba(43, 39, 80, .95);

    --avatar-background-color: rgb(68, 65, 94);

    --light-border-color: rgba(255, 255, 255, .3);

    --counter-color: var(--brand-dark-green);

    --form-input-color: transparent;

    --brand-link-color: rgb(159 198 253);

    --bottom-pop-bg-color: rgb(229 240 255);
    

    --text-shadow:
        0px 1px 1px rgba(0, 0, 0, 0.2),
        0px 0px 3px rgba(0, 0, 0, 0.5),        
        0px 0px 20px rgba(0, 0, 0, 0.3);
}