.feed .feed-slides li {
  position: fixed;
  bottom: calc(-100vh);
  width: 100vw;
  overflow-x: hidden;
  /* transition: bottom .2s ease-in-out; */
 /*  background-color: black; */
  width: 100vw;
  max-width: var(--app-width);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;    
}

.feed .feed-slides li.viewport {
  bottom: 0;
}

.feed .feed-slides li img {
  width: 100vw;
  height: unset;
}

.feed .feed-slides li .feed-video {
  width: 100vw;
  max-width: var(--app-width);
}

.feed .feed-slides li img.portrait,
.feed .feed-slides li .feed-video.portrait {
  width: unset;
  max-width: unset;
  height: 100vh;

}

.feed .feed-slides li button.video-pause {
  position: absolute;
}

.ease-in {
  transition: bottom .2s ease-in-out;
}