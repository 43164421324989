.subscribe-wrapper {
    background-color: white;
    margin: 1rem;
    border-radius: 10px;
}

.subscribe {
    text-shadow: none;
    /* background-color: var(--brand-background-color); */
    height: calc(100% - 8rem);
    padding-top: 2rem;
    /* padding-bottom: 4rem; */
}

.subscribe .tag {
    margin: .1rem;
    background-color: transparent;
    border-color: silver;
}

.subscribe div.hero {
    width: unset;
}

.subscribe .hero .subscribe-hero-header {   
    position: relative;   
    width: unset;
    max-width: var(--app-width);
    text-align: center;
}

.subscribe .hero .subscribe-hero-header .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, .7);

}

.subscribe .wait-logo img {
    width: 60px;
    margin-bottom: 1rem;
}

.subscribe .hero .subscribe-hero-header .hero-inner {
    position: relative;
    /*  z-index: 99;
    text-shadow: var(--text-shadow);
    color: white; */
}

.subscribe .hero .subscribe-hero-header .hero-avatar {
    margin: unset;
    margin-top: -2.5rem;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
    display: inline-block;
    /*  border: 1px solid var(--light-border-color);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .8); */
    box-sizing: border-box;
    position: relative;
    border: none;
}

.subscribe .subscribed-athlete {
    padding-top: .25rem;
}

.subscribe .cc-card {
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid var(--counter-color);
    border-radius: 5px;
    display: flex;

    max-width: 400px;
    align-items: center;
    padding: 1rem;
    background-color: var(--light-background-color);
}

.subscribe .cc-card.faded {
    border-color: var(--light-background-color);
}

.subscribe .cc-card .card-checkbox {
    margin-right: .5rem;
}

.subscribe .cc-card .card-image {
    width: 60px;
    margin-right: 1rem;
}

.subscribe .cc-card .card-details {
    text-align: left;
}

.subscribe .unlock-content {
    max-width: 400px;
    margin: auto;
    /* padding: 0 2rem 2rem; */
    padding-bottom: 1rem;
    position: relative;
}

.subscribe .unlock-content .unlock-logo img {
    width: 50px;
}

.subscribe .unlock-content .unlock-price {
    background: gainsboro;
    display: inline-block;
    color: black;
    padding: 1rem;
    margin-top: -7px;
    border-radius: 0 0 20px 20px;
    position: relative;
    box-shadow: 1px 5px 5px black;
}

.subscribe .unlock-content .unlock-price::before,
.subscribe .unlock-content .unlock-price::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0px;
    left: -6px;
    border: 3px solid;
    border-color: transparent #777777 #777777 transparent;
}

.subscribe .unlock-content .unlock-price::after {
    left: unset;
    right: -6px;
    border-color: transparent transparent #777777 #777777;
}

.subscribe .unlock-content .unlock-features {
    background-color:var(--bottom-pop-bg-color);
    margin: .5rem 1rem 1rem;
    padding: 1rem;
    border-radius: 10px;
}

.subscribe .unlock-content .unlock-features .unlock-feature {
    padding: .5rem .5rem .5rem 2rem;    
    background-color:rgba(255,255,255,.8);
    text-align: left;
    margin-top: .4rem;
    border-radius: 3px;
}

.subscribe .unlock-content .unlock-features .unlock-feature i {
    display: inline-block;
    background-color: rgba(57, 175, 255, .2);
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 100%;
}

.subscribe .subscribe-processing {
    padding: 0 1rem 1rem 1rem;
    height: calc(99vh - var(--ios-top-pad) - var(--header-bar-height) - 4rem);
}


.subscribe .unlock-content .unlock-total-price {
    display: flex;
    text-align: left;
    margin: 1rem .5rem;
    padding: .5rem .25rem;
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
}