.hint-pop {
    position: absolute;
    /* left: 50%;
    transform: translateX(-50%);   */
    box-sizing: border-box;
    width: 325px;

    z-index: 99999;
    overflow: hidden;
    margin: auto;
}

.hint-pop .hint-title {
    display: flex;
    align-items: center;
    text-align: left;
    padding: .5rem .5rem 0 .75rem;
}

.hint-content {
    padding: 1rem;
    text-align: left;
    color: black;
    background-color: white;
    margin: .5rem;
    border-radius: 10px;
}

.hint-sheen {
    position: fixed;
    top: 0;
    width: var(--app-width);
    height: 100vh;
    background-color: rgba(0, 0, 0, .1);
    opacity: 1;
    transition: all .3s ease-in-out;
    z-index: 99;
}

.hint-wrapper {
    height: 100vh;
    width: 100vw;
    max-width: var(--app-width);
    position: relative;
    border-radius: 5px;
    opacity: 0;
    /* transition: opacity .3s ease-in; */
}

.hint-wrapper.visible {
    opacity: 1;
    transition: opacity .3s ease-in-out;
}

.hint-pop .hint-inner {
    display: inline-block;
    max-width: 325px;
    position: relative;
}

.hint-pop .hint-inner .hint-children-wrapper {
    margin: 1rem;
    position: relative;
    border-radius: 5px;

}

.hint-pop .hint-inner .hint-children {
    /* padding: 1.2rem; */
    background-color: var(--bottom-pop-bg-color);
    border: 1px solid var(--bottom-pop-bg-color);
    color: var(--brand-blue);
    border-radius: 5px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 1px 3px 8px black;
    
}



.hint-pop .hint-caret {
    position: absolute;
    display: inline-block;
    color: var(--bottom-pop-bg-color);
    font-size: 2.5rem;
}

.hint-pop .hint-buttons {
    padding: .5rem .75rem;
    /* background-color: var(--hint-border-color); */
    display: flex;
    align-items: center;
    margin-top: .5rem;
    border-radius: 0 0 8px 8px;
}

/* --MAIN MENU-- */
.hint-pop.hint-main-menu {
    top: 75px;
    right: 0px;
}

.hint-pop.hint-main-menu .hint-caret {
    top: -21px;
    right: 3px;
}

/* --SEARCH-- */
.hint-pop.hint-search {
    top: 75px;
    left: 0;
}

.hint-pop.hint-search .hint-caret {
    top: -21px;
    left: 4px;
}

/* --TABS-- */
.hint-pop.hint-tabs {
    top: 117px;
    left: 50%;
    transform: translateX(-50%);
}

.hint-pop.hint-tabs .hint-caret {
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
}

/* --ADD-CONTENT-- */
.hint-pop.hint-add-content {
    bottom: 62px;
    left: 50%;
    transform: translateX(-50%);
    

}   

.hint-pop.hint-add-content .hint-caret {
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--bottom-pop-bg-color);
}

/* --REPORT CONTENT-- */
.hint-pop.hint-report {
    bottom: 51px;
    right: 38px;
}

.hint-pop.hint-report .hint-caret {
    right: -13px;
    bottom: 32px;
}

/* --LIKES CONTENT-- */
.hint-pop.hint-likes {
    bottom: 86px;
    right: 38px;
}

.hint-pop.hint-likes .hint-caret {
    right: -13px;
    bottom: 42px;
}

/* --CREATOR NAME-- */
.hint-pop.hint-creator {
    bottom: 150px;
    left: 5px;
}

.hint-pop.hint-creator .hint-caret {
    bottom: -22px;
    left: 20px;
    color: var(--bottom-pop-bg-color);
}

/* --FINAL SCREEN-- */
.hint-pop.hint-final {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.hint-pop.hint-final .hint-caret {
    display: none;
}

.hint-pop ol {
    padding-left: 1rem;
    margin: .25rem 0;
}

.hint-pop ol li {
    padding-bottom: .25rem;
}