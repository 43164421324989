.header {
    position: fixed;    
    top: var(--ios-top-pad);
    z-index: 99999;
    /* background-color:var(--main-header-color); */
    display: flex;
    text-align: center;
    align-items: center;
    width: 100vw;
    max-width: var(--app-width);
    height: var(--header-bar-height);
    border-color: transparent;
    border-collapse: collapse;
    margin-bottom: -1px;
  }

  .header.nav-bar {
    background-color: var(--nav-header-color);
  }
  
  .header-item {
    font-size: 1.25rem;
    display: inline-block;
    position: relative;
    width: 55px;
    margin: 0 .25rem;
  }

  .header-item.center-item {
    flex: 1;
    text-align: center;
    display: inline-block;
    width: unset;
  }

  .header .header-search {
    display: flex;
    align-items: center;
    border: 1px solid rgba(255,255,255,.3);
    padding: .3rem .75rem;
    border-radius: 5px;
    color: silver;
    box-shadow: 1px 1px 1px rgba(0,0,0,.2);
    text-shadow: 1px 1px 1px rgba(0,0,0,.5);
  }

 

  .header-item .header-logo {
  width: 32px;
  }

  .header-item a, .header-item .anchor-button {
    color: white;
    padding: .75rem;
    font-size: 1.25rem;
    text-shadow: 1px 1px 1px rgba(0,0,0,.2);
  }
  
  .header-item.selected a {
    color:red;
  }
  
  .header-item.add-button {
    font-size: 2rem;
  }

  .header-item .counter {    
    top: 3px;
    right: 12px;    
  }

  .header .header-avatar {  
      border-radius: 100%;
      width: 30px;
      height: 30px;
  
  }

  .header .header-item button.header-add-button {
    padding: 0;
    font-size: 1.5rem;
    margin-right: .5rem;
  }

  
  