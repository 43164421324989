.subscribers .search-outer {
   /*  position: fixed; */
    width: 100vw;
    max-width: var(--app-width);
    margin: auto;
    box-sizing: border-box;
    padding-top: .75rem;
    padding-bottom: .5rem;
   /*  background-color: black; */
}

.subscribers .search-bar .searchbar input {
    width: unset;
}
.subscribers li {
    padding: .5rem 1rem;
    border-bottom: 1px solid var(--light-background-color);
}

.subscribers  {
   
    height: calc(100vh - 133px);
    overflow-y: auto;
    padding-bottom: 2rem;
}

.subscribers .subscribers-list {
    padding-top: 4rem;
}