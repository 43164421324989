.profile {
  background-color: var(--app-background-color);
}

.profile .hero {
  text-align: left;
  /* background-color: rgba(255, 255, 255, .13); */
  display: flex;
  flex-direction: column;
  /* min-height: calc(100vh - var(--header-bar-height));  */
  align-items: center;
  /* justify-content: center; */
  /*  scroll-snap-align: center; */
  position: relative;
  /* padding-top: calc(var(--header-bar-height)/2);
  padding-bottom: calc(var(--header-bar-height)/2); */
  margin-top: 1rem;
  padding-bottom: 5rem;
}

.profile .feed-alert {
  margin-top: 1rem;
  text-align: center;
}

.profile .status-wrapper {
  text-align: center;
 /*  background-color: rgba(255, 255, 255, .12);
  padding: .1rem 0 0 0; */
  z-index: 99;
}

.profile .publish-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: var(--app-width);
  margin-bottom: 1rem;
}

.profile .publish-status .status {
  margin: 0;
  font-size: .75rem;
  text-align: center;
  opacity: .6;
  position: relative;
  padding: 0 .4rem;
}

.profile .publish-status .status.selected {
  opacity: 1; 
  font-weight: bold;
}
.profile .publish-status .status .caret {
  opacity: 0;
  display: block;
  position: absolute;
  text-align: center;
  left: 0;
  width: 100%;
  bottom: -10px;
  font-size: 1rem;
}
.profile .publish-status .status.selected .caret {
  opacity: 1;
}

.profile .admin-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding-top: .5rem;
  background-color: black;
}

.profile .admin-buttons button {
  border-right: 1px solid var(--drop-menu-border-color);
  padding: 0 .75rem;
}

.profile .admin-buttons button:last-child {
  border: none;
}

/* .profile .hero .hero-image {    
  height: 200px; 
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
} */
.profile .hero .hero-image img {
  width: 100%;
}

.profile .hero .avatar-wrapper {
  margin-top: -2rem;
  z-index: 9;
}

.profile .hero .hero-avatar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
  border: 2px solid black;
  background-color: gainsboro;
}

.profile .hero-header {
  display: block;
  text-align: center;
  width: 100%;
}

/* .profile .hero .hero-header .hero-avatar {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgba(255,255,255,.2);
} */

.profile .profile-actions {

  text-align: center;

  padding: 0 0 1rem 0;
}

/* .profile .profile-actions .requested,
.profile .profile-actions .approved {
  background-color: var(--brand-red);
} */

.profile .profile-actions .none {
  background-color: var(--counter-color);
}

.profile .profile-actions .action-buttons {
  margin-top: 1rem;
  margin-bottom: 0;
}

.profile .profile-actions .action-buttons button {
  font-size: .85rem;
}

.profile .profile-actions .profile-action {
  margin: .25rem;
  padding: 1rem 0;
  font-size: .9rem;
  width: 90px;
}

.profile .profile-actions .action-buttons i {
  font-size: 1rem;
}

.profile .profile-description {
  padding: .5rem;
  text-align: center;
  line-height: 1.5rem;
  ;
}

.profile h1 {
  font-size: 1.3rem;
  font-weight: normal;
  margin-bottom: 0;
}

.profile .bio {
  border-top: 1px solid var(--light-border-color);
  border-bottom: 1px solid var(--light-border-color);
  padding: .5rem 0;
  margin-top: .5rem;
}

/* .profile .feed-list {
  padding-top: 0 !important;
}

.profile .feed {
  padding: 0;
} */

.profile .feed ul li {
 /*  position: relative;
  top: 0;
  padding: 0;
  box-sizing:content-box; */
  /*  height: 100vh; */

  /* padding-top: calc(var(--header-bar-height)/2);
  padding-bottom: calc(var(--header-bar-height)/2); */
  height: calc(100vh + var(--header-bar-height) + var(--header-bar-height));
  
  
}

.profile .banner {
  font-size: 1rem;
  margin: 1rem 0 0 0;
}

.profile .feed .feed-image {
  height: calc(100vh + var(--header-bar-height) + var(--header-bar-height));;
}

.profile .footer-mini {
  bottom: 2rem;
}