.report-content {
  padding: 1rem;
  padding-bottom: 3rem;
  text-align: left;
  
}

.report-content .report-details {
  max-width: 600px;
}

.report-content h3 {
  font-size: 1rem;
  margin: 0;
  padding-bottom: .25rem;
}

.report-content .content-selector {
  padding-top: 1rem;
}

.report-content ul.content-selector li {
  list-style: none;  
  text-align: left;
  padding: .5rem 1rem;
  font-size: .9rem;
  border: none;
  margin: 0; 
  scroll-snap-align:unset;
  height: unset; 
  display: block;
}

.report-content .content-selector li label {
  display: flex;
  align-items: center;
}

.report-content .content-selector li label input {
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.report-content .report-buttons {
  text-align: center;
  
}

.report-content .report-buttons button {
  margin-left: 1rem;
}

.report-content ul li .report-comment {
  width: 95%;
  padding: 0 .5rem; 
 background-color: white;
 color: black;
 border-radius: 5px;
}

.report-content .report-confirmation {
  text-align: center;
  min-height: 30vh;
}