.feed {
    padding: 0;
    margin-top: 1rem;
    /* border-top: 10px solid var(--brand-background-color); */
}

.feed ul li.no-data {
    margin-top: calc(6rem + var(--ios-top-pad));
}

.feed ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
}

.feed ul li {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
    height: 100vh;
    /* calc(100vh - var(--header-bar-height) - var(--tab-bar-height)); */
    padding-top: 0;
    /* calc((var(--header-bar-height) + var(--tab-bar-height))/2); */
    padding-bottom: 0;
    /*  calc((var(--header-bar-height) + var(--tab-bar-height))/2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feed ul :first-child {
    border-top: none;
}

.feed .feed-header {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0rem 1rem 1rem;
    /* background-color: var(--light-background-color); */

}

.feed .filtered-top {
    padding-top: 5rem;
}

.feed .avatar {
    margin-right: 1rem;
}

.feed .avatar img {
    border-radius: 100%;
    width: 46px;
    height: 46px;
}

.feed .feed-headline {
    display: flex;
    padding: .25rem 1rem;
    position: relative;
}

.feed .feed-headline .fa-ellipsis {
    font-size: .9rem;
}

.feed .not-published .publish-lock {
    display: flex;

    color: white;
    text-shadow: 1px 2px 3px black;
    width: 180px;
    height: 80px;
    line-height: 100px;
    border-radius: 0 10px 10px 0;
    border: 1px solid dimgray;
    border-left: none;
    background-color: rgba(0, 0, 0, .5);
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: .5rem;
    position: absolute;
    bottom: 13rem;
    left: 0;
    text-align: left;
}

.feed .feed-footer {
    display: flex;
    color: white;
    align-items: flex-end;
    padding: 0 .75rem 0 .75rem;
    margin-bottom: 6.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: var(--app-width);
    box-sizing: border-box;
    background-position: bottom;
    /* background: -webkit-linear-gradient(0deg, rgba(223, 229, 250, 0) 100%, rgba(0, 0, 0, 0.3533788515406162) 0%); */
}

.feed .feed-footer .feed-filter {
    font-size: 1rem;
    margin-left: .5rem;
    padding: .25rem;
    color: white;
    border-radius: 5px;

}

.feed .feed-footer .feed-filter.selected {

    color: var(--brand-green);

}

.feed .feed-footer .feed-actions {
    text-align: center;
}

.feed .feed-footer .feed-actions button {
    font-size: 1.3rem;
    margin: auto;
}

.feed .creator-name button {
    font-weight: bold;
}

.feed .feed-actions button {
    font-size: 1.5rem !important;
}

.feed .feed-description {
    padding: 0;
    margin-right: 1rem;
    flex: 1 1;
    text-shadow: var(--text-shadow);
    font-weight: bold;
}



.feed .feed-image {
    padding: 0;
    text-align: center;
    width: 100%;
    max-width: var(--app-width);
    height: calc(100vh - 2px);
    /*  calc(100vh - var(--header-bar-height) - var(--tab-bar-height)); */
   /*  background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    position: relative; */
}

.feed .item-loading {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    opacity: .5;
}

/* .feed .feed-image.portrait {
    background-size: cover;
} */

.feed .feed-image.feed-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.feed .feed-image.feed-video video {
    width: 100%;
}

.feed .feed-image.feed-video video.portrait,
.feed .feed-video.portrait {
    width: unset;
    height: calc(100vh - 2px);
}

.feed .feed-image.feed-video .plyr__controls {
    display: none;
}

.feed .feed-image.feed-video button.plyr_control {
    display: none;
}

.feed .feed-image.feed-video .video-pause {
    position: absolute;
    top: 45%;
    z-index: 999;
}

.feed .feed-image img {
    width: 100%;
    max-width: var(--app-width);
    max-height: 100vh;
    box-sizing: border-box;
    /* border-radius: 5px; */
}

.feed .feed-video.edit-video {
    width: 100%;
    max-width: var(--app-width);
}


.feed .feed-date {
    font-size: .8rem;


}

.feed ul li.live-feed-item {
    border: 1px solid rgba(255, 255, 255, .2);
    background-color: rgba(255, 255, 255, .13);
    margin: 6% 3%;
    border-radius: 5px;
    padding: 2rem 1rem;
    text-align: center;
    position: relative;
}

.feed .live-feed-item .live-feed-footer {
    display: block;
    align-items: center;
    /*  margin-top: 1rem;  */
    /*  border-top: 2px solid black; */
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem;
    padding-bottom: 0;
    color: var(--brand-red);
}

.feed .live-feed-icon {
    font-size: 1rem;
    color: rgba(255, 255, 255, .8);
    margin-left: 1rem;
}

.feed .live-feed-item .live-feed-footer .live-feed-join {
    color: white;
    padding: 1rem;
    margin-top: 1rem;

}

.feed .live-feed-item .stream-timer {
    font-size: 1.25rem;
    color: var(--brand-text-color);

}

.feed .live-feed-item .avatar {
    margin: 0;
}

.feed .live-feed-item .close-live-feed {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 1rem;
    color: silver;
}

.feed .description-pop {
    padding: .25rem 1.5rem 4rem;
    text-align: left;
}

.feed .description-content {
    background-color: white;
    padding: 1rem;
    margin-top: .25rem;
    border-radius: 10px;
    max-height: 300px;
    overflow: auto;
}

.feed .description-pop-footer {
    text-align: right;
    padding: .5rem 1rem;
    margin-top: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
}