.popover {
  position: fixed;  
  width: 100%;
  max-width: var(--app-width);
  background-color: var(--popover-body-color);
  z-index: 9999999;
  top: 0px;
  /* left: 0; */
  height: 100%;
  transform: translateY(100vh);
  transition: transform .4s cubic-bezier(0, .52, 0, 1);
}

.popover.visible {
  transform: translateY(0);
}

.popover .popover-header {
  display: flex;
  padding: 0 1rem;
  align-items: center;
  background-color: var(--popover-header-color);
  height: var(--header-bar-height);
  margin-top: var(--ios-top-pad);
}

.popover .popover-header button {
  padding: .25rem .5rem;
}

.popover .popover-content {
  background-color: black;
  height: calc(100vh - var(--header-bar-height) - var(--ios-top-pad));
  /* scroll-snap-type: y proximity; */
  overflow-y: auto;
}
.popover .popover-content.scrollSnap {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}