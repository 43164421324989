.publish-channel ul {
   display: inline-block;
   max-width: 500px;
}

.publish-channel ul li {
    padding: 1rem 0;
    position: relative;
    text-align: left;
    height: unset;
    display: block;
    scroll-snap-align: unset;
}

.publish-channel ul li .icon {
    width: 38px;
    text-align: center;
    
}

.publish-channel .publish-success {
    text-align: left;
    width: 280px;
    display: inline-block;
}

.publish-channel .unpublish {
    padding: 1rem;
}

.publish-channel .unpublish ul li {
    text-align: left;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.publish-channel .unpublish .confirm-unpublish {
    margin-top: 1rem;
}

.publish-channel .unpublish .confirm-unpublish input {
    padding: .5rem;
    width: 250px;
    margin-top: .25rem;
    text-align: center;
    color: red;
    font-weight: bold;
}