.refer-friend {
    padding-top: 1rem;
}

.refer-friend button {
    width: 200px;
    padding: 1rem;
    position: relative;
}

.refer-friend button .copy-confirm {
    opacity: 0;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 2rem;
    transition: all .3s ease-in-out;
}

.refer-friend button .copy-confirm.show-confirm {
    opacity: 1;
    transition: all .3s ease-in-out;
}
