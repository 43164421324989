.axcess-admin .counter {
  top: -5px;
  right: -2px;
  color: var(--brand-gold);
}

.axcess-admin .reports .fixed-header {
  /* position: fixed; */
  width: 100%;
  max-width: var(--app-width);

  /* top: calc(var(--header-bar-height) + var(--ios-top-pad));
   z-index: 9; */
}

.axcess-admin .reports table {
  padding: 1%;
  position: relative;
}

/* .axcess-admin .reports td {
   color: black;
   background-color: rgba(81, 151, 255, .15)
} */

.axcess-admin .reports td,
.axcess-admin .reports th {
  font-size: 0.9rem;
  padding: 0.75rem 0.35rem;
}

.axcess-admin .reports th {
  position: sticky;
  top: 0;
  /*    background-color: rgb(81, 151, 255);
 */
}

.axcess-admin .reports .views-scroller {
  overflow-y: auto;
  height: calc(100vh - 194px);
}

.axcess-admin .activity-dashboard .item {
  text-align: center;
}

.axcess-admin .slideover.activity-top .slideover-header {
  background-color: var(--bottom-pop-bg-color);
  color: black;
  border-radius: 3px 3px 0 0;
}

.axcess-admin .slideover.activity-top .slideover-content {
  background-color: var(--bottom-pop-bg-color);
}

.axcess-admin .tabs .tab button {
  font-size: 0.9rem;
}

.axcess-admin-wrapper .download {
  padding: 0.39rem 0.5rem;
  border: 1px solid var(--light-border-color);
  border-radius: 3px 0 0 3px;
}
