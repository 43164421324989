.add-content {

    padding-bottom: 2rem;
    min-height: 30vh;

}

.add-content .processing {
    height: 40px;
    box-sizing: border-box;
}

.add-content .chooser-buttons {
    /*  background-color: black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*   height: calc(100vh - var(--header-bar-height)); */
}

.add-content .chooser-block {
    display: block;
    /*  background-color: var(--light-background-color); */
    /*  border: 1px solid var(--light-border-color); */
    /* margin: auto; */
    padding: 1rem;
    margin-bottom: 1rem;
    /* border-radius: 5px;     */
    width: 350px;
    box-sizing: border-box;

}

.add-content .chooser-block button,
.add-content .chooser-block label {
    display: inline-block;
    text-align: center;
    margin-bottom: .5rem;
    width: 200px;
    padding: 1rem;

}

.add-content .chooser-block .chooser-icon {
    font-size: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
}

.add-content .chooser-block.hidden {
    display: none;
}

.add-content .image-wrapper {
    padding: .5rem 4rem;
}

.add-content .add-content-uploaded-image {
    width: 100%;
    box-sizing: border-box;

}

.add-content .add-content-uploaded-video {
    max-height: 400px;
}

.add-content .chooser-form {
    max-width: 500px;
    margin: auto;
    padding: 0 .5rem;
}

.add-content .chooser-form form {
    border-top: none;
}

.add-content .chooser-button-bar button {
    font-size: .9rem;
}

.add-content .chooser-button-bar button {
    margin: .5rem;
    width: 150px;
}

.add-content .video-player,
.add-content .video-buttons {
    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 9999999;
}

.add-content .video-player.visible,
.add-content .video-buttons.visible {
    display: block;
}

.add-content .video-buttons-inner {
    position: fixed;
    top: 0;
    left: 0;
}

.add-content textarea {
    color: black;
    background-color: white;
    border-radius: 5px;
    padding: .5rem;
}

.add-content .confirm-wrapper {
    height: 25vh;
    padding-top: 1rem;
}

.add-content .edit-replace {
    position: absolute;
    color: white;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: var(--brand-blue);
    border-radius: 100%;
    right: 46px;
    z-index: 9999;
    top: 40px;
    font-size: 1.2rem;  
    border: 3px solid var(--bottom-pop-bg-color);
}