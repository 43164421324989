
.profile-mini {
  color: black;
}


.profile-mini .hero {
 
  position: relative;  
  background-color: white;
  margin: 3.75rem 2rem 2rem;
  border-radius: 10px;
  padding: 1rem;
}

/* .profile-mini .hero .hero-image { 
  opacity: .3;
  width: 100vw;
  height: 50vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size:cover;  
  background-color: rgba(0, 0,0, 0.5);
  position: absolute;
} */

.profile-mini .hero .hero-image img {
  width: 100%;
}



.profile-mini .hero .hero-avatar {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  display: inline-block;
  background-color: white;
  margin-top: -60px;
}

.profile-mini .hero-header {
  display: block;
  text-align: center;
  width: 100%;
}

.profile-mini .profile-description {
  padding-top: .5rem;
}

.profile-mini .profile-actions {

  text-align: center;
  margin-top: 1rem;
}

.profile-mini .profile-actions button {
  width: 120px;
  font-size: .9rem;
}

.profile-mini h1 {
  font-size: 1.3rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

.profile-mini .tag {
  background: transparent;
}

