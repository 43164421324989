.error-msg {
    border-radius: 5px;
    text-align: left;
    color: white;
}
.error-msg.fixed {
    position: fixed;
    top: calc(10px + var(--ios-top-pad));
    width: 300px;
    max-width: var(--app-width);
    box-sizing: border-box;
    z-index: 8899999999;
    left: -500px;    
    transition: all .4s ease-in-out;
    box-shadow: 1px 2px 4px black;
}

.error-msg.fixed.show {    
    left: 50%;
    transform: translateX(-50%);
    
}

.error-msg.relative {
    margin: 1rem;
   
}

.error-msg.warn {
    background-color: #800027;
}

.error-msg .error-header {
    display: flex;
    align-items: center;
    text-align: left;
    padding: .5rem;
}

.error-msg .error-message {
    padding: 0 1rem 1rem;
}