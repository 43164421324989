.image-cropper .box {
    display: inline-block;
   width: 100%;
   height: 300px;
    box-sizing: border-box;
}

.image-cropper .img-preview {
    overflow: hidden;      
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.image-cropper .img-preview.hero {
    height: 250px;
    width: 100%;
}

.image-cropper .img-preview.square {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    display: inline-block;
}


.image-cropper .button-bar {
    display: inline-flex;
    align-items: center;
    text-align: center;
    font-size: .9rem;
    margin-bottom: .5rem;
}
.image-cropper .button-bar button, .image-cropper .button-bar .button {
    font-size: .9rem;
    margin: .5rem;
}

