.home-feed {
  height: 100vh; /* calc(100vh - var(--header-bar-height)); */
  /* background-color: black; */
  /* padding-top: var(--header-bar-height); */
  /* position: relative; */
  overflow-y: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.loading-more {
 /*  position: absolute;
  bottom: 5px;
  right: 5px; */
  text-align: right;
  color: white;
  padding: .5rem;
  padding-bottom: 2rem;
  z-index: 99;
  border-radius: 2px;
  font-size: .75rem;
  
}

.home-feed .top-filter {
  position: absolute;
  left: -4px;
  top: -3px;
  font-size: .7rem;
  border: none;
  color: var(--brand-green);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
}

.home-feed.scroll-stop {
  overflow-y: hidden;
}

.home-feed .feed,
.feed {
  margin-top: 0;
}

.home-feed .feed-list,
.feed-list {
  /* padding-top: 2rem; */
  background-color: var(--app-background-color);
  /* padding-bottom: 5rem; */

}

.home-feed .live-alert {
  position: absolute;
  top: -8px;
  right: -5px;
  font-size: 1rem;
}

.home-feed .tabs {
  position: fixed;
  top: calc(var(--header-bar-height) + var(--ios-top-pad));
  z-index: 9;
  margin-top: -1px;
  padding-top: 1px;
}

/* .home-feed .tabs:focus-visible {
  outline: none;
} */

.home-feed .tabs .tab {
  width: 30%;
  text-align: center;
  display: inline-block;
  border-collapse: collapse;
}

.home-feed .tabs .tab button {
  border-collapse: collapse;
}


/* .home-feed .tabs .tab:focus-visible {
  outline: none;
} */

/* 
.home-feed .tabs .tab:last-child {
  border: none;
}
 */
