.dialog-pop {
    position: fixed;
    /* left: 50%;
    transform: translateX(-50%);   */ 
    box-sizing: border-box;
    width: 100%;
    max-width: var(--app-width);
    z-index: 99999999;
    overflow: hidden;
    margin: auto;
    top:0;
    transform: translateY(-100vh);
    transition: transform .4s cubic-bezier(0, .52, 0, 1);
    text-align: center;
}

.dialog-pop.visible {
    transform: translateY(50px);    
}

.dialog-sheen {
    position: fixed;
    top: 0;
    width: var(--app-width);
    height: 100vh;
    background-color:  rgba(0,0,0,.6);
    opacity: 0;
    transition: all .3s ease-in-out;
}

.dialog-sheen.show {
    opacity: 1;
}

.dialog-pop .dialog-inner {
    display: inline-block;
    background-color: var(--brand-background-color);
    width: 90%;
    max-width: 500px;
    
    border: 1px solid var(--drop-menu-border-color);
    border-radius: 3px;
    position: relative;
}

.dialog-pop .dialog-inner .dialog-header {
    display: flex;
    align-items: center;
    padding-left: .75rem;
}

.dialog-pop .dialog-inner button.dialog-close { 
    padding: .75rem;
}
.dialog-pop .dialog-inner .dialog-children {
    padding: 1rem;   
}