.bottom-pop-wrapper .hint-sheen {
  position: fixed;
  top: 0;
  width: var(--app-width);
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;

  z-index: 999998;
}

.bottom-pop-wrapper .hint-sheen.visible {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.bottom-pop {
  position: fixed;
  width: 100vw;
  max-width: var(--app-width);
  /* background-color: rgb(231, 233, 246); */
  background: var(--bottom-pop-bg-color);
  /* background: -moz-radial-gradient(circle, rgba(231, 233, 246,1) 0%, rgba(223,229,250,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(231, 233, 246,1) 0%, rgba(223,229,250,1) 100%);
  background: radial-gradient(circle, rgba(231, 233, 246,1) 0%, rgba(223,229,250,1) 100%);  */
  z-index: 9999999;
  bottom: 0px;
  /* left: 0; */
  /*  height: 50%; */
  max-height: calc(99vh - var(--ios-top-pad));
  transform: translateY(100vh);
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .6);

}

.bottom-pop.visible {
  transform: translateY(0);
}

.bottom-pop.hide {
  transform: translateY(100vh);
  opacity: 0;
  transition: .3s ease-out;
}

.bottom-pop .bottom-pop-header {
  display: flex;
  padding: 1rem 1rem 0 1rem;
  align-items: center;
  background-color: transparent;
  height: var(--header-bar-height);
  color: black;
}

.bottom-pop .bottom-pop-header img {
  width: 30px;
}

.bottom-pop .bottom-pop-header button {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(0, 0, 0, .08);
  border-radius: 100px;
  font-size: .9rem;
}

.bottom-pop .bottom-pop-content {
  background-color: transparent;
  color: black;
  max-height: calc(99vh - var(--ios-top-pad) - var(--header-bar-height) - 1rem);
  overflow-y: auto;
  overflow-x: hidden;
}