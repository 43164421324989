.help {
   
    color: black;
    padding-bottom: 2rem;
    text-align: left;
    max-height: calc(100vh - var(--header-bar-height) - var(--ios-top-pad));
}

.help .help-header {
    background-color: var(--brand-purple);
    font-weight: bold;
    font-size: 1.2rem;
    color: white;
    padding: 1rem;
}

.help .help-body {
    padding: 1rem;    
    margin: 1rem;
    background-color: white;
    border-radius: 10px;    
}

.help .help-body .help-topic .help-topic-header {
    font-weight: bold;
    
}

.help .help-body .help-topic p {
    margin: 0;
    margin-bottom: 1rem;
}

.help .help-footer {
    text-align: center;
    margin-top: 1rem;    
}

.support-content {
    text-align: left;
    padding: 1rem;
}
.support-content textarea {
    border: none;
    padding: .5rem;
    width: 100%;
    box-sizing: border-box;
}
.support-content ul li {
    padding: .5rem 0;
}
.support-content ul li label {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: center;
}

.slideover.support-slider .slideover-header, .popover.support-slider .popover-header {
    background-color: var(--bottom-pop-bg-color);
    color: black;
    border-radius: 3px 3px 0 0;
}

.slideover.support-slider .slideover-content, .popover.support-slider .popover-content {
    background-color: var(--bottom-pop-bg-color);
    color: black;
}