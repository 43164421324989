.search-bar .search-wrapper {
    border-radius: 10px;
    background-color: black;
    width: 90%;
    max-width: 500px;
    border: 1px solid dimgray;
    margin: auto;
    height: 41px;
    overflow: hidden;
    transition: height .3s ease-in-out;
}

.search-bar .searchbar {
    display: flex;
    align-items: top;
    padding: .25rem;
}


.search-bar .searchbar button.search-close {

    display: flex;
    padding: .5rem 1rem;
}

.search-bar .searchbar .icon {
    color: silver;
    padding: .5rem 0 .5rem 1rem;
}

.search-bar .searchbar input {
    background-color: transparent;
    border: none;
    text-align: left;
    display: block;
    color: white;
    font-size: 1rem;
    padding: .5rem .5rem .5rem 0;
    width: 200px;
    border: none;
}

.search-bar .searchbar input:active,
.search-bar .searchbar input:focus-visible {
    border: none;
    outline: none;
}