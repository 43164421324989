.center-pop {
    z-index: 99999;
    position: relative;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.center-pop .sheen {
    z-index: 99999;
    
}

.center-pop .pop-header{
    text-align: right;
    display: flex;
    align-items: center;
    padding: 1rem 0;    
}

.center-pop .pop-header .header-logo {
    width: 30px;
}

.center-pop .center-pop-inner {
    /* position: absolute; */
    z-index: 99999;
   /*  top: 50%; */
    box-sizing: border-box;
    /* transform: translateY(-50%);
    transition: transform .4s cubic-bezier(0, .52, 0, 1); */
    width: 100vw;
    max-width: var(--app-width);
    padding: 1rem;
}

.center-pop .center-pop-children {
    font-size: 1rem;
    padding: 0 1rem 1rem;
    width: 100%;
   
    background-color: var(--bottom-pop-bg-color);
    box-shadow: 1px 3px 8px black;
    border-radius: 5px;
    box-sizing: border-box;
    color: black;
    text-shadow: none;
    
   
}

.center-pop .center-pop-children .center-pop-content {
    padding: 1rem;
   border-radius: 10px;
    background-color: white;
}

.center-pop .center-pop-children .center-pop-content.footer-space {
    padding-bottom: 4rem;
}