.text-shadow {
    text-shadow: var(--text-shadow);
}
.round-10 {
    border-radius: 10px;
}
.hidden-opacity {
    opacity: 0;
}
.b-round-5 {
    border-radius: 5px;
}
.hidden {
    display: none;
}

.p-025 {
    padding: .25rem;
}

.p-05 {
    padding: .5rem;
}

.p-1 {
    padding: 1rem;
}

.p-left-025 {
    padding-left: .25rem;
}

.p-left-05 {
    padding-left: .5rem;
}

.p-left-1 {
    padding-left: 1rem;
}

.p-right-025 {
    padding-right: .25rem;
}

.p-right-05 {
    padding-right: .5rem;
}

.p-right-1 {
    padding-right: 1rem;
}

.p-top-025 {
    padding-top: .25rem;
}

.p-top-05 {
    padding-top: .5rem;
}

.p-top-1 {
    padding-top: 1rem;
}

.p-bottom-025 {
    padding-bottom: .25rem;
}

.p-bottom-05 {
    padding-bottom: .5rem;
}

.p-bottom-1 {
    padding-bottom: 1rem;
}

.m-025 {
    margin: .25rem;
}

.m-05 {
    margin: .5rem;
}

.m-1 {
    margin: 1rem;
}

.m-left-025 {
    margin-left: .25rem;
}

.m-left-05 {
    margin-left: .5rem;
}

.m-left-1 {
    margin-left: 1rem;
}

.m-right-025 {
    margin-right: .25rem;
}

.m-right-05 {
    margin-right: .5rem;
}

.m-right-1 {
    margin-right: 1rem;
}

.m-top-025 {
    margin-top: .25rem;
}

.m-top-05 {
    margin-top: .5rem;
}

.m-top-1 {
    margin-top: 1rem;
}

.m-bottom-025 {
    margin-bottom: .25rem;
}

.m-bottom-05 {
    margin-bottom: .5rem;
}

.m-bottom-1 {
    margin-bottom: 1rem;
}

.margin-auto {
    margin: auto;
    text-align: center;
}

.flex {
    display: flex;
    align-items: center;
    align-content: center;
}

.flex-1 {
    flex: 1 1;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.v-align-middle {
    vertical-align: middle;
}

.border-box {
    box-sizing: border-box;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.block {
    display: block;
}

.bold {
    font-weight: bold;
}

.small-font {
    font-size: .9rem;
    line-height: 1.3rem;
}

.smaller-font {
    font-size: .75rem;
    line-height: 1.2rem;
}

.medium-font {
    font-size: 1.1rem;
}

.large-font {
    font-size: 1.2rem;
    line-height: 1.65rem;
}

.larger-font {
    font-size: 1.75rem;
    line-height: 1.85rem;
}

.small-caps {
    font-variant: small-caps;
}

.wide {
    width: 100%;
}

.relative {
    position: relative;
}

.fade {
    opacity: 0;

}

.fade.fade-in {
    opacity: 1;
    transition: opacity .5s ease;
}

.faded {
    opacity: .7;
    transition: all .3s ease-in-out;
}

.muted-text {
    color: rgb(175, 172, 172);
    font-weight: normal;
}

.underline {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.disabled-link {
    pointer-events: none;
    opacity: .7;
}

.apple-switch {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    width: 35px;
    height: 20px;
    background-color: gainsboro;
    border: 1px solid #D9DADC;
    border-radius: 100px;
    box-shadow: inset -16px 0 0 0 var(--drop-menu-border-color);
    transition: all .1s ease-in-out;
    margin: 0;
}

input.apple-switch:focus {
    border-color: #D9DADC;
}

/* .apple-switch:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
  }
   */
.apple-switch:checked,
.apple-switch.checked {
    box-shadow: inset 14px 0 0 0 var(--counter-color);
    border-color: var(--counter-color);
}

.apple-switch:checked:after,
.apple-switch.checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

.settings-block ul.block {
    background-color: var(--light-background-color);
    margin: .5rem;
    border-radius: 5px;
}

.settings-block ul.block li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    border-top: 1px solid rgba(255, 255, 255, .05);
    padding: 1rem;
    text-align: left;
    position: relative;
}

.settings-block ul.block li .menu-icon {
    font-size: 1rem;
    margin-right: .75rem;
}

.settings-block ul.block li .menu-icon.chevron {
    margin-right: 0;

}

.progress-bar {
    left: 0;
    bottom: 8px;

    height: 4px;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 139, 72, .9);
    opacity: 0;
    border-radius: 3px 10px 10px 3px;
}

.progress-bar.grow {
    width: 0;
    opacity: 1;
    /*  transition: width .750s ease-in;     */
    animation: prgBar 1s alternate;
    animation-iteration-count: 1;
    position: absolute;
}

.vh100 {
    height: 100vh;
}

.vw100 {
    width: 100vw;
    max-width: var(--app-width);
}

.transparent {
    background-color: transparent !important;
}

@keyframes prgBar {
    0% {
        width: 0%
    }

    9.99% {
        width: 0%
    }

    10% {
        width: 10%
    }

    95% {
        width: 100%
    }
}

.b-1 {
    border: 1px solid var(--light-border-color);
}

.b-bottom {
    border-bottom: 1px solid var(--light-border-color);
}

.b-top {
    border-top: 1px solid var(--light-border-color);
}

.b-left {
    border-left: 1px solid var(--light-border-color);
}

.b-right {
    border-right: 1px solid var(--light-border-color);
}

.no-wrap {
    white-space: nowrap;
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: rgba(0,0,0,.6);
    border-top: 1px solid rgba(255,255,255,.5);
    border-bottom: 1px solid rgba(255,255,255,.5);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    /* font: 700 18px/1 'Lato', sans-serif; */
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: 0px;
    left: -7px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}

.pop-window {
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem;
}