.activity-dashboard {   
    background-color: var(--bottom-pop-bg-color);
}

.activity-dashboard .fixed-header {
    /* position: fixed; */
    width: 100%;
    max-width: var(--app-width);

    /* top: calc(var(--header-bar-height) + var(--ios-top-pad));
    z-index: 9; */
}

.activity-dashboard .big-tabs .big-tab button {
    width: 115px;
}

.activity-dashboard .data-area {
    margin-top: 6.5rem;

}

.activity-dashboard .total-summary {
    text-align: left;
    padding: 1rem;
    color: black;
}

.activity-dashboard .data-area.short {
    margin-top: 2rem;
}



.activity-dashboard table {
    padding: 1%;
    position: relative;
}

.activity-dashboard td {
    color: black;
    background-color: rgba(81, 151, 255, .15)
}


.activity-dashboard td,
.activity-dashboard th {
    font-size: .9rem;
    padding: .75rem .35rem;
}

.activity-dashboard th {
    position: sticky;
    top: 0;
    background-color: rgb(81, 151, 255);
}

.activity-dashboard .views-scroller {
    overflow-y: auto;
    height: calc(100vh - 194px);
   
}

.activity-dashboard .feed {
    margin-top: 0;
}


.activity-dashboard .date-select {
    padding: 1rem;
    max-width: 350px;
    margin: auto;
}

.activity-dashboard .date-select select {
    background-color: white;
    color: black;
}

.activity-dashboard .summary-area {
    /*  display: inline-flex; */
    color: black;
    padding-bottom: 4rem;
    background-color: var(--bottom-pop-bg-color);
}

.activity-dashboard .summary-area .item {
    margin: .5rem;
    border-radius: 5px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: inline-block;
    width: 45%;
    box-sizing: border-box;
}

.activity-dashboard .summary-area .item.wide {
    width: 93%;
}

.item-header {
    display: flex;
    align-items: center;
    font-size: .95rem;
    padding: .5rem 1rem;
    background-color: rgba(229, 240, 255, .5);       
}

.activity-dashboard .summary-area .item .item-content {
    padding: 2rem 1rem;
    font-size: 2rem;
    color: #5197ff;   
}

.activity-dashboard .summary-area .item .item-content .sub-content{
    padding-top: 1rem;
    font-size: .9rem;
   
}

.activity-dashboard .item-helper {
    position: absolute;
    top: 0;
    right: 0;
    padding: .5rem;
    color: blueviolet; 
}

.activity-dashboard .summary-area .item .item-footer {
    padding: 0 1rem 1rem;
    font-size: 1.2rem;
    color: var(--brand-dark-green);
    
}

.slideover.activity-top .slideover-header {
    background-color: var(--bottom-pop-bg-color);
    color: black;
    border-radius: 3px 3px 0 0;
}

.slideover.activity-top .slideover-content {
    background-color: var(--bottom-pop-bg-color);
}

.activity-dashboard .search-outer {
    padding-bottom: .5rem;
}

.activity-dashboard .search-outer .search-bar .search-wrapper {
    background-color: white;
    border-color: rgba(0, 0, 0, 0.2);
}

.activity-dashboard .search-outer .search-wrapper .searchbar input {
    color: black;
}

.activity-dashboard .search-outer .search-wrapper .searchbar button.search-close {
    color: black;
}