.search-main .feed-searchbar {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: var(--app-width);
  background-color: black;
  z-index: 199999;
  padding-bottom: 1rem;
  padding-top: var(--ios-top-pad);
  transform: translateY(-50vh);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(85, 83, 83);
  height: 100vh;
}

.search-main .feed-searchbar.visible {
  transform: translateY(0);
}

.search-main .feed-searchbar .feed-searchbar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 0.5rem 1.5rem;
}

.search-main .feed-searchbar .feed-searchbar-inner .search-tab {
  margin-right: 1rem;
  padding: 0.25rem;
  border-bottom: 1px solid transparent;
  color: silver;
}
.search-main .feed-searchbar .feed-searchbar-inner .search-tab.selected {
  border-color: white;
  color: white;
}

.search-wrapper {
  border-radius: 10px;
  background-color: black;
  width: 90%;
  max-width: 500px;
  border: 1px solid dimgray;
  margin: auto;
  /*   height: 41px;  */
  overflow-y: auto;
  transition: height 0.3s ease-in-out;
}

.searchbar {
  display: flex;
  align-items: top;
  padding: 0.25rem;
  position: relative;
}

.search-wrapper.search-grow {
  /* height: calc(100vh - 42px - var(--ios-top-pad)); */
}

.search-wrapper .search-results {
  margin: 0 1rem;
  border-top: 1px solid dimgray;
}

.search-wrapper .search-results .search-sport-item {
  margin-right: 0.25rem;
  display: inline;
}

.search-wrapper .search-results .search-sport-item.hidden {
  display: none;
}

.search-wrapper .search-results .search-sport-item::after {
  content: ",";
}

.search-wrapper .search-results .search-sport-item:last-child {
  margin-right: 0;
}
.search-wrapper .search-results .search-sport-item:last-child::after {
  content: "";
}

.search-wrapper .search-results ul {
  overflow-y: auto;
  height: calc(100vh - 100px - var(--ios-top-pad));
}

.search-wrapper .search-results ul li {
  padding: 0.5rem 0;
}

.searchbar button.search-close {
  display: flex;
  padding: 0.5rem 1rem;
}

.searchbar .icon {
  color: silver;
  padding: 0.5rem 0 0.5rem 1rem;
}

.searchbar input {
  background-color: transparent;
  border: none;
  text-align: left;
  display: block;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  width: 200px;
  border: none;
}

.searchbar input:active,
.searchbar input:focus-visible {
  border: none;
  outline: none;
}

.searchbar .favorites-filter {
  /* font-size: 1.2rem; */
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.35);
  padding: 0.5rem 0.75rem;
  border-left: 3px solid black;
  border-radius: 0 30px 30px 0;
}

.searchbar .favorites-filter.selected {
  color: var(--brand-pink);
}
